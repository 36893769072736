import { parse } from 'query-string';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { DocuSign, OrgansationdecryptData, fetchUrl, onDecryptData } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';
export const EMPLOYEEEMAIL_SUCCESS = 'app/AuthenticationPage/EMPLOYEEEMAIL_SUCCESS';
export const ORGANISATIONID_SUCCESS = 'app/AuthenticationPage/ORGANISATIONID_SUCCESS';
export const SHOW_USER_SUCCESS = 'app/AuthenticationPage/SHOW_USER_SUCCESS';
export const ORGANISATIONEMAIL_SUCCESS = 'app/AuthenticationPage/ORGANISATIONEMAIL_SUCCESS';
export const SALESAGENCY_REQUEST = 'app/AuthenticationPage/SALESAGENCY_REQUEST';
export const SALESAGENCY_SUCCESS = 'app/AuthenticationPage/SALESAGENCY_SUCCESS';
export const SALESAGENCY_ERROR = 'app/AuthenticationPage/SALESAGENCY_ERROR';
export const GET_DOCUCONTRACT_ID_REQUEST = 'app/AuthenticationPage/GET_DOCUCONTRACT_ID_REQUEST';
export const GET_DOCUCONTRACT_ID_SUCCESS = 'app/AuthenticationPage/GET_DOCUCONTRACT_ID_SUCCESS';
export const GET_DOCUCONTRACT_ID_ERROR = 'app/AuthenticationPage/GET_DOCUCONTRACT_ID_ERROR';


const initialState = {
  ischeckprovider: false,
  isdecrypted: null,
  isorgansationId: null,
  firstname:null,
  lastname:null,
  isSaleAgency: null,
  invitedEmailSent:null,
  organsationEmail: null,
  AgentUserId: null,
  saleagencyName:null,
  docuCode:null,

}
export default function AuthenticationPage(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEEEMAIL_SUCCESS:
      return { isdecrypted: payload.eamilDecrypted,isorgansationId:payload.organsationId,firstname:payload.firstname,
      lastname:payload.lastname
      };
      case ORGANISATIONID_SUCCESS:
        return { organsationEmail: payload.organsationEmail,AgentUserId:payload.AgentUserId,saleagencyName:payload.saleagencyName,
        };
      case SHOW_USER_SUCCESS:
        {
          return state;
        }
        case SALESAGENCY_REQUEST:
      return {
      };
    case SALESAGENCY_SUCCESS:

      return {
        isSaleAgency: payload.saleeamilDecrypted,invitedEmailSent:payload.invitedEmailSent
      };
    case SALESAGENCY_ERROR:
      return { ...state};
    default:
      return state;
  }
}
export const emailemployeeSuccess = (res) => ({ type: EMPLOYEEEMAIL_SUCCESS, payload: res });
export const emailorgansationSuccess = (res) => ({ type: ORGANISATIONID_SUCCESS, payload: res });
export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});
export const SaleAgencyRequest = () => ({ type: SALESAGENCY_REQUEST });
export const SaleAgencySuccess = (res) => ({ type: SALESAGENCY_SUCCESS, payload: res});

export const SaleAgencyError = error => ({ type: SALESAGENCY_ERROR, payload: error, error: true });

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};


//employee login through link
const employeetoken =  (params)=> async(dispatch) => {
  // const url = search && search.length ? parse(search.slice(1)) : {};
  const token = params.EmployeeUrl.toString()
  onDecryptData(token)
  .then((res)  => {
    const inviteEmail = res.decrypted.email
    const organsationId = res.decrypted.organsationId
    const firstname = res.decrypted.firstname
    const lastname = res.decrypted.lastname
    dispatch(emailemployeeSuccess({ eamilDecrypted: inviteEmail,organsationId: organsationId,firstname:firstname,lastname:lastname}))
 
  })
}
// Organsation login through link
//OrgansationdecryptData
const organsationtoken =  (params)=> async(dispatch) => {
  const token = params.organisationUrl.toString()
   OrgansationdecryptData(token)
  .then((res)  => {
    const inviteEmail = res.decrypted.email
    const AgentUserId = res.decrypted.AgentUserId
    const saleagencyName = res.decrypted.saleagencyName
    dispatch(emailorgansationSuccess({ organsationEmail: inviteEmail,AgentUserId: AgentUserId,saleagencyName:saleagencyName}))
 
  })
}

// Sales agency login through link
export const fetchAutoLoginUrl = (params) => (dispatch) => {
  return fetchUrl(params).then(res => {
    const inviteEmail = res.responseData.email
    const invitedEmailSent = res.responseData.invitedEmailSent
    dispatch(SaleAgencySuccess({  saleeamilDecrypted: inviteEmail, invitedEmailSent:invitedEmailSent}))
    return res;
  }).catch((e) => {

    //return storableError(e)
  });
}

export const loadData = (params, search) => async (dispatch) => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  const searchValues = search && search.length ? parse(search.slice(1)) : {};
  if (searchValues && searchValues.code) {
    await dispatch(fetchAutoLoginUrl({ providerUrl: searchValues.code, id: searchValues.id }));
  }
  const employeevalues = search && search.length ? parse(search.slice(1)) : {};
  if (employeevalues && searchValues.user) {
    await dispatch(employeetoken({ EmployeeUrl: searchValues.user }));
  }
  const organsationvalues = search && search.length ? parse(search.slice(1)) : {};
  if (organsationvalues && organsationvalues.organisation) {
    await dispatch(organsationtoken({ organisationUrl: organsationvalues.organisation }));
  }
  // await dispatch(organsationtoken(search))
  // // await dispatch(employeetoken(search))
  return dispatch(fetchPageAssets(pageAsset, true));
};
     