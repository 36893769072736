import React, { useEffect, useState } from 'react';
import css from './QRCodePage.module.css';
import { useDispatch } from 'react-redux';
import { signup } from '../../../ducks/auth.duck';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { LayoutSingleColumn } from '../../../components';
import TopbarContainer from '../../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../../containers/FooterContainer/FooterContainer';
import classNames from 'classnames';
import { generateQrCode, verifyQrCode } from '../../../util/api';

function QRCodePage() {
  const [secret, setSecret] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [token, setToken] = useState('');
  const [verificationResult, setVerificationResult] = useState('');
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useDispatch();
  useEffect(() => {
    generateSecret();
  }, []);

  const generateSecret = async () => {
    try {
      const response = await generateQrCode();
      if (response?.status) {
        setSecret(response.secret);
        setQrCode(response.qrCode);
        localStorage.setItem('secret', response.secret);
        localStorage.setItem('qrCode', response.qrCode);
      }
      setVerificationResult(response?.message);
    } catch (error) {
      setVerificationResult(error?.message);
      console.error('Error generating secret', error);
    }
  };

  const verifyToken = async () => {
    try {
      let body = {
        token,
        secret,
      };
      const response = await verifyQrCode(body);
      if (response.status) {
        state.params.qrSecret = secret;
        dispatch(signup(state.params));
      }
      setVerificationResult(response?.message);
    } catch (error) {
      setVerificationResult(error.message);
      console.error('Error verifying token', error);
    }
  };

  const topbarClasses = classNames({
    [css.hideOnMobile]: false,
  });

  return (
    <>
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer className={topbarClasses} type="verify" />}
        footer={<FooterContainer />}
      >
        <div className={css.container}>
          <h3>
            Please scan the QR code using your Microsoft Authenticator app to complete your signup.
          </h3>

          {qrCode && (
            <>
              <img src={qrCode} alt="Centered" className={css.centered_image} />
              <p>
                <span>Secret Code:</span>
                <span>{secret}</span>
              </p>
              <br />
              <input
                type="text"
                placeholder="Enter 2FA token"
                value={token}
                onChange={e => setToken(e.target.value)}
                className={css.input_box}
              />
              {verificationResult && verificationResult == 'Invalid token' && (
                <p className={css.errorMessage}>{verificationResult}</p>
              )}
              <div className={css.buttonWrapper}>
                <button onClick={verifyToken} className={css.submit_button}>
                  Verify Token
                </button>
                <button className={css.submit_button} onClick={generateSecret}>
                  Re-Generate QR Code
                </button>
              </div>
            </>
          )}
        </div>
      </LayoutSingleColumn>
    </>
  );
}

export default QRCodePage;
