import React, { useEffect, useState } from 'react';
import css from './QRCodePage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TopbarContainer from '../../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../../containers/FooterContainer/FooterContainer';
import classNames from 'classnames';
import { LayoutSingleColumn } from '../../../components';
import { verifyQrCode } from '../../../util/api';
import { VerifyInitiate, isVerifiedSuccess, logout } from '../../../ducks/auth.duck';

function VerifyCode(props) {
  const [token, setToken] = useState('');
  const [verificationResult, setVerificationResult] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem('isVerified')) {
      dispatch(VerifyInitiate());
    }
  }, []);
  let secret = useSelector(
    state => state?.user?.currentUser?.attributes?.profile?.publicData?.qrSecret || ''
  );

  const verifyToken = async () => {
    try {
      let body = {
        token,
        secret,
      };
      const response = await verifyQrCode(body);
      if (response.status) {
        localStorage.removeItem('isVerified');
        dispatch(isVerifiedSuccess());
        history.push('/');
      }
      setVerificationResult(response?.message);
    } catch (error) {
      setVerificationResult(error?.message);
      console.error('Error verifying token', error);
    }
  };

  const topbarClasses = classNames({
    [css.hideOnMobile]: false,
  });

  const handleNavigation = () => {
    localStorage.clear();
    //dispatch logout
    dispatch(logout());
    setTimeout(() => {
      history.push('/login');
    }, 1000);
  };

  return (
    <>
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer className={topbarClasses} type="verify" />}
        footer={<FooterContainer />}
      >
        <div className={css.container} style={{ paddingTop: '80px' }}>
          <h3>Please enter the code from your Microsoft Authenticator app to log in..</h3>
          <input
            type="text"
            placeholder="Enter 2FA token"
            value={token}
            onChange={e => setToken(e.target.value)}
            className={css.input_box}
          />
          {verificationResult && verificationResult == 'Invalid token' && (
            <p className={css.errorMessage}>{verificationResult}</p>
          )}

          <button onClick={verifyToken} className={css.submit_button} style={{ width: 'auto' }}>
            Verify Token
          </button>
          <h4>
            {' '}
            Back to{' '}
            <a
              onClick={() => {
                handleNavigation();
              }}
            >
              login
            </a>
          </h4>
        </div>
      </LayoutSingleColumn>
    </>
  );
}

export default VerifyCode;
